<template>
  <el-select
    class="select"
    :style="{ width: width }"
    size="mini"
    v-model="searchValue"
    :loading="isLoading"
    filterable
    remote
    clearable
    :remote-method="getList"
    @clear="getList"
    placeholder="请输入关键词"
  >
    <el-option v-for="item in list" :label="item.store_title" :value="item.id" :key="item.id"></el-option>
  </el-select>
</template>

<script>
import { getListAPI } from './api.js'
export default {
  name: 'SelectMerchantType',

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    width: {
      type: String,
      default: '200px'
    }
  },

  computed: {
    searchValue: {
      get() {
        return this.value
      },

      set(val) {
        this.$emit('input', val)
      }
    }
  },

  data() {
    return {
      list: [],
      isLoading: false
    }
  },

  created() {
    this.getList('')
  },

  methods: {
    async getList(query) {
      let params = {
        page: 1,
        page_size: 20,
        store_title: query
      }
      let res = await getListAPI(params)
      this.list = res.data
    }
  }
}
</script>

<style scoped lang="scss">
.select /deep/ {
  .el-select__caret:first-child::before {
    content: '\e6e1';
  }
  .is-focus {
    .el-select__caret:first-child {
      transform: rotateZ(0deg);
    }
  }
}
</style>
