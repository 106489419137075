<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form :model="searchData" inline @submit.native.prevent>
      <el-form-item label="订单号">
        <el-input style="width: 200px" v-model="searchData.order_sn" clearable size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="用户电话">
        <el-input style="width: 200px" v-model="searchData.mobile" clearable size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="寄存点">
        <SelectMerchant v-model="searchData.store_id"></SelectMerchant>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- list -->
    <el-table :data="list">
      <el-table-column prop="order_id" label="订单号" min-width="120">
        <template slot-scope="{ row }">
          {{ row.order && row.order.order_sn }}
        </template>
      </el-table-column>
      <el-table-column prop="content" label="评价内容" min-width="150"> </el-table-column>
      <el-table-column prop="coin" label="商户信息" min-width="150">
        <template slot-scope="{ row }">
          {{ row.store && row.store.store_title }}
        </template>
      </el-table-column>
      <el-table-column prop="coin" label="用户信息" min-width="150">
        <template slot-scope="{ row }">
          <div>昵称：{{ row.user && row.user.nickname }}</div>
          <div>电话：{{ row.user && row.user.mobile }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="评价时间" min-width="160" show-overflow-tooltip fixed="right"> </el-table-column>
      <el-table-column prop="id" label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <!-- <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleDetail(row)">详情</el-link> -->
          <el-link type="danger" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, delAPI } from './api'
import SelectMerchant from '@/views/merchant/merchant-list/select-merchant-list.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { SelectMerchant },
  data() {
    return {
      list: [],
      searchData: {
        page: 1,
        page_size: 10,
        order_sn: '',
        store_id: '',
        mobile: ''
      },
      total: 0
    }
  },

  watch: {},
  activated() {},
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const res = await getListAPI(this.searchData)
      this.list = res.data
      this.total = res.total
    },
    handleDel({ id }) {
      this.$confirm('确认删除该评价吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
