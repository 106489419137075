import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/api/company/order/comment/list`,
    method: 'get',
    params
  })
}

export function delAPI(id) {
  return http({
    url: `/api/company/order/comment/del`,
    method: 'post',
    data: { id }
  })
}
export function addAPI(data) {
  return http({
    url: `/api/company/workCity/add`,
    method: 'post',
    data
  })
}

export function editAPI(data) {
  return http({
    url: `/api/company/workCity/edit`,
    method: 'post',
    data
  })
}

export function getDetailAPI(id) {
  return http({
    url: `/api/company/order/comment/detail`,
    method: 'get',
    params: { id }
  })
}
